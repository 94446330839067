@font-face {
    font-family: 'Roboto';
    src: url("../fonts/Roboto-Regular.ttf");
    font-weight: 400
}

@font-face {
    font-family: 'Roboto';
    src: url("../fonts/Roboto-Medium.ttf");
    font-weight: 500
}

@font-face {
    font-family: 'Roboto';
    src: url("../fonts/Roboto-Bold.ttf");
    font-weight: 700
}

html, body {
    height: 100%;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden
}

body {
    background-color: #bfbfbf;
    background-image: linear-gradient(#bfbfbf, grey);
}

.medicine-bg {
    background-color: #bfbfbf;
    background-image: linear-gradient(#bfbfbf, grey);
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
}

.account-bg {
    background-color: #bfbfbf;
    background-image: linear-gradient(#bfbfbf, grey);
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    height: 100vh;
}

.wrapper {
    width: 100vw;
    height: calc(100vh - 60px);
    background-image: url("../images/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 60px;
}

.wrapper .flex-con {
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.lower-opacity {
    opacity: 0.5;
    filter: grayscale(100%);
}

@media (min-width: 470px) and (min-height: 910px) {
    .wrapper .flex-con {
        position: relative;
        top: 50%;
        transform: translateY(-50%)
    }
}

.bg-alpha {
    background-color: rgba(255, 255, 255, 0.6);
    text-align: center;
    min-height: calc(100vh - 110px);
    padding-bottom: 35px;
    overflow-y: auto;
    border-radius: 6px;
}

@media (min-width: 350px) {
    .bg-alpha {
        margin: 0 auto
    }
}

@media (min-width: 380px) {
    .bg-alpha {
        width: 340px
    }
}

@media (min-width: 470px) and (min-height: 910px) {
    .bg-alpha {
        min-height: unset
    }
}

@media (min-width: 700px) {
    .bg-alpha {
        width: 400px
    }
}

.bg-alpha h6.title {
    margin: 5px 10px 15px 10px;
    font-size: 14px
}

@media (min-width: 350px) {
    .bg-alpha h6.title {
        font-size: 16px;
        min-height: 45px
    }
}

@media (min-width: 700px) {
    .bg-alpha h6.title {
        margin: 5px 10px 20px 10px;
        font-size: 18px
    }
}

.bg-alpha .flex-con-wrapper {
    padding: 15px;
    overflow-x: auto
}

@media (min-width: 470px) and (min-height: 910px) {
    .bg-alpha .flex-con-wrapper {
        height: unset
    }
}

.emergency-main-buttons {
    background-color: #fff;
    width: 90%;
    margin: 0 auto 15px auto;
    display: inline-flex;
    min-height: 110px;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0 0 7px 2px #606060
}

@media (min-width: 350px) {
    .emergency-main-buttons {
        width: 280px;
        height: 150px
    }
}

.emergency-main-buttons .em-title {
    background-color: #ffd600;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center
}

@media (min-width: 350px) {
    .emergency-main-buttons .em-title {
        height: 30%
    }
}

.emergency-main-buttons .em-title h6 {
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    font-size: 12px
}

@media (min-width: 350px) {
    .emergency-main-buttons .em-title h6 {
        font-size: 15px
    }
}

@media (min-width: 700px) {
    .emergency-main-buttons .em-title h6 {
        font-size: 16px
    }
}

@media (min-width: 350px) {
    .emergency-main-buttons .em-img {
        height: 70%;
        display: flex;
        justify-content: center
    }
}

.emergency-main-buttons .em-img img {
    width: 60px;
    margin: 10px
}

.container-fluid {
    max-height: calc(100vh - 90px);
    padding: 15px
}

a {
    color: #000
}

a:hover {
    text-decoration: none;
    color: #000
}

@media (min-width: 350px) {
    .button-wrapper {
        height: calc(100% - 65px)
    }
}

@media (min-width: 470px) {
    .button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
}

@media (min-width: 470px) and (min-height: 910px) {
    .button-wrapper {
        justify-content: center;
        height: unset
    }
}

.main-header {
    height: 60px;
    background-color: #ffd600;
    position: fixed;
    width: 100%;
    box-shadow: 0 0 7px 2px #606060;
    align-items: center;
    top: 0;
    z-index: 101;
    justify-content: space-between;
}

.header {
    height: 60px;
    background-color: #ffd600;
    position: fixed;
    width: 100%;
    box-shadow: 0 0 7px 2px #606060;
    align-items: center;
    top: 0;
    z-index: 10;
    justify-content: space-between;
}

.header .title-header h5 {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 18px;
}

@media (min-width: 421px) {
    .header .title-header h5 {
        font-size: 20px
    }
}

.header .arrow-header {
    margin-right: 15px;
    font-size: 13px
}

.header .arrow-header i {
    vertical-align: middle;
}

@media (min-width: 421px) {
    .header .arrow-header i {
        vertical-align: sub;
    }
}

.page-content {
    min-height: calc(100vh - 60px);
    margin-top: 60px;
    padding-top: 15px;
    z-index: 5;
    margin-bottom: 60px;
}

.page-content-no-margin-bottom {
    margin-bottom: 0;
}

.page-content.storage {
    min-height: calc(100vh - 120px)
}

.page-content .content-title {
    background-color: #ffd600;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 15px
}

@media (min-width: 470px) {
    .page-content .content-title {
        width: 426px;
        margin-left: auto;
        margin-right: auto
    }
}

.page-content .content-title p {
    margin: 0
}

.page-content .page-div {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    position: relative;
    z-index: -2;
    pointer-events: auto;
    overflow: hidden;
}

.extra-margin-bottom {
    margin-bottom: 60px !important;
}

.border-radius-all {
    border-radius: 6px;
}

.border-radius-bottom {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.border-radius-top {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#passwordChange .bg-content {
    right: -170px;
}

/*.page-content .page-div.storage-container {*/
/*max-width: 410px;*/
/*margin: 0;*/
/*min-height: calc(100vh - 140px);*/
/*padding: 10px;*/
/*left: 50%;*/
/*transform: translateX(-50%);*/
/*}*/
.storage-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (min-width: 470px) {
    .page-content .page-div {
        padding: 25px;
        width: 426px;
        margin-left: auto;
        margin-right: auto
    }
}

.page-content .page-div .bg-content {
    background-repeat: no-repeat;
    background-position: 114% 5%;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    /*right: 0;*/
    opacity: 0.06;
    z-index: -1;
    overflow: hidden;
    pointer-events: none
}

.page-content .page-div p {
    margin: 0;
    line-height: 1.3
}

.page-content .page-div p.margin-bottom-20 {
    margin-bottom: 20px
}

.page-content .page-div p.margin-top-20 {
    margin-top: 20px
}

.page-content .page-div p.margin-bottom-10 {
    margin-bottom: 10px
}

.page-content .page-div p.margin-top-10 {
    margin-top: 10px
}

.page-content .page-div p span.deco {
    display: inline-block;
    position: relative;
    padding-left: 20px
}

.page-content .page-div p span.deco:before {
    position: absolute;
    width: 9px;
    height: 92%;
    content: '';
    background-color: #ffd600;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

.page-content .page-div hr {
    margin: 7px 0;
    border-color: rgba(0, 0, 0, 0.28)
}

.default-button {
    border-radius: 0;
    color: #000;
    text-transform: uppercase;
    background-color: #ffd600;
    border: 1px solid #000;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal
}


.default-button.short {
    width: 115px;
    float: right;
}

.default-button.green-btn {
    background-color: #acd718
}

.default-button.orange-btn {
    background-color: #ff9600
}

.default-button i {
    font-size: 11px;
    margin: 0 5px
}

.default-button i.fa-phone {
    transform: scaleX(-1)
}

.default-button.right-btn {
    width: calc(50% - 7px);
    display: inline-flex;
    margin-left: 7px
}

.default-button.left-btn {
    width: calc(50% - 7px);
    display: inline-flex;
    margin-right: 7px
}

.page-content .page-div .info-container .img-con {
    display: inline-block;
    vertical-align: top
}

.page-content .page-div .info-container .img-con img {
    max-height: 70px;
    width: 70px
}

.page-content .page-div .info-container .par-con {
    display: inline-block;
    width: calc(100% - 70px)
}

.page-content .page-div .info-container .par-con p {
    padding-left: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px
}

.margin-bottom-15 {
    margin-bottom: 15px
}

.margin-bottom-10 {
    margin-bottom: 10px
}

.margin-top-10 {
    margin-top: 10px
}

.margin-top-20 {
    margin-top: 20px
}

.margin-top-15 {
    margin-top: 15px
}

.back {
    cursor: pointer
}

@media (min-width: 470px) {
    .page-content.col-12 {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 45px;
    }
}

@media (min-width: 470px) {
    .header.col-12 {
        padding-left: 30px;
        padding-right: 30px
    }
}

ul, ol {
    padding-left: 22px;
    margin-top: 1rem
}

ul {
    list-style: none
}

ul li:before {
    content: '';
    width: 9px;
    height: 9px;
    background-color: #222;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: -16px
}

ul li {
    position: relative
}

.centering {
    height: 100vh;
    width: 100vw;
    position: relative
}

p.center {
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    color: #555;
    margin: 0;
    font-size: 22px;
}

.fa-spinner {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    margin-right: 10px;
}

.no-pointer-events {
    pointer-events: none;
    z-index: -1;
}

a.d-block {
    display: inline-block !important;
    margin: 0 10px 5px 0;
}

.header-icon {
    width: 220px;
}

.header-icon img {
    width: 19px;
}

.header-icon:not(:last-child) {
    margin-right: 8px;
}

@media (min-width: 351px) {
    .header-icon img {
        width: 26px;
    }

    .header-icon:not(:last-child) {
        margin-right: 12px;
    }
}

.header-icons {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

@media (max-width: 351px) {
    .header-icons {
        height: 19px;
    }
}

.input-with-button {
    width: 100%;
    display: block;
}

.input-with-button input {
    width: 60%;
    height: 38px;
    border-radius: 0;
    border: 1px solid #8c8c8c;
    padding: 5px 10px;
}

.input-with-button button.default-button {
    width: 120px;
    margin-top: 0;
}

textarea {
    width: 100%;
    min-height: 120px;
    padding: 8px 6px;
    color: #333;
}

input {
    color: #333;
    height: 38px;
    border-radius: 0;
    border: 1px solid #8c8c8c;
    padding: 5px 10px;
    margin-bottom: 10px;
    outline-style: none;
    box-shadow: none;
}

.hide-element {
    display: none;
}

#notCordovaPopup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    background: white;
    border: 1px solid grey;
    padding: 15px;
    width: 300px;
    box-shadow: 0 0 8px 3px grey;
    text-align: center;
    border-radius: 6px;
}

#errorPopup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    background: white;
    border: 1px solid grey;
    padding: 15px;
    width: 300px;
    box-shadow: 0 0 8px 3px grey;
    text-align: center;
    border-radius: 6px;
}

.floating-button {
    bottom: 5px;
    position: fixed;
    left: 50%;
    width: calc(100vw - 10px);
    transform: translateX(-50%);
    border-radius: 5px;
    text-transform: none;
    height: 45px;
}

.floating-button img {
    margin-right: 10px;
}

.speech-button {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #8c8c8c;
    margin: 20px 6px 10px 6px;
    display: inline-flex;
}

.speech-button.listening {
    background-color: #acd718;
}

.speech-button i {
    font-size: 28px
}

.speech-translator {
    text-align: center;
}

.currency-calculator {
    text-align: center;
}

.speech-translator .page-div .bg-content {
    background-position: center;
}

.currency-input {
    text-align: end;
    width: 100%;
    height: 38px;
}

.currency-disabled {
    pointer-events: none;
}

.currency-rates {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    flex-direction: column;
}

.currency-row {
    display: flex;
    flex-direction: row;
    margin: 14px auto;
}

.currency-input-div {
    width: 70%;
    margin-right: 5px;
}

.currency-input-div input {
    padding: 5px 10px;

}

.currency-names {
    width: 100%;
    height: 38px;
}

.height-75 {
    height: 86px;
}

.height-20 {
    height: 20px;
}

option:checked {
    display: none;
}

.currency-select {
    width: 30%;
}

.currency-rate-button {
    width: 70%;
    margin-right: 5px;
    height: 43px;
}

.button-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.currency-switch-button {
    width: 30%;
    height: 43px;
}

.currency-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: -15px;
}

.icon-rotate i {
    transform: rotate(90deg);
    font-size: 16px;
}

.readonly-input input {
    background-color: white;
    font-weight: bold;
}

.login-background {
    background-color: #bfbfbf;
    background-image: linear-gradient(#bfbfbf, grey);
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    height: 100vh;
}

.bg.login-bg {
    max-width: 426px;
    /*min-width: 290px;*/
    width: 90vw;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 100vh;
}

.login-bg .em-img {
    text-align: center;
    margin-bottom: 24px;
    margin-top: 30px;
}

.login-bg input :first-child {
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
    height: 37px;
    border: 1px solid #000;
    text-align: center;
    box-shadow: 0 0 6px 0 grey;
}

.login-bg input {
    width: 100%;
    margin-bottom: 0;
    margin-top: 8px;
    height: 37px;
    border: 1px solid #000;
    text-align: center;
    box-shadow: 0 0 6px 0 grey;
}

.bg {
    height: 100vh;
}

.bg.bg-menu {
    overflow: hidden;
}

.login-bg h1 {
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 30px;
}

.login-bg img {
    margin-bottom: 10px;
    width: 120px;
}

/*input:focus {*/
/*outline-style: solid;*/
/*outline-color: #ffe76b;*/
/*outline-offset: -3px;*/
/*outline-width: 4px;*/
/*}*/

.logo-in-spinner {
    width: 80px;
    display: block;
    margin: 0 auto 20px auto;
    opacity: 0.6
}

.login-bg .default-button {
    box-shadow: 0 0 6px 0 grey;
}

.login-bg .small {
    margin: 10px;
    text-align: center;
    cursor: pointer;
}

.login-bg .normal-text {
    text-transform: none;
    font-weight: 300;
    margin-bottom: 16px;
    margin-top: -20px;
}

i.bigger-icon {
    font-size: 28px;
    color: black;
}

@media (max-width: 351px) {
    i.bigger-icon {
        font-size: 20px;
    }
}

.pointer-cursor {
    cursor: pointer;
}

.small.default-cursor,
.default-cursor {
    cursor: default;
}

.download-app {
    position: absolute;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}

.download-app p {
    margin-bottom: 5px;
}

.menu-header i.bigger-icon {
    margin-right: 12px;
}

.extra-margin {
    top: 80px;
    position: absolute;
    z-index: 999;
    right: 150px;
}

.fa-comment {
    margin-right: 10px;
}

/*# sourceMappingURL=global.css.map */

#fallPopup {
    position: absolute;
    top: 45vh;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 999;
    background: white;
    border: 1px solid grey;
    width: 300px;
    box-shadow: 0 0 8px 3px grey;
    text-align: center;
    border-radius: 6px;
}

#fallPopup .popup-title {
    height: 38px;
    background-color: #ffd600;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 5px;
    color: red;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.popup-title p {
    margin-bottom: 0;
}

#fallPopup .popup {
    padding: 15px;
}

.countdown-clock {
    z-index: 50;
}

.react-countdown-clock {
    margin-top: 10px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

#savePopup {
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    background: white;
    border: 1px solid grey;
    width: 300px;
    box-shadow: 0 0 15px 0px grey;
    text-align: center;
    border-radius: 6px;
}

#savePopup .popup-title {
    height: 38px;
    background-color: #ffd600;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#savePopup .popup {
    padding: 15px;
}

.popup-background {
    background-color: grey;
    height: 100vh;
    width: 100vh;
}

.fall-detector {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fall-detector p {
    padding-right: 5px;
}

.folder-div .fas, .file-div .far {
    font-size: 35px;
}

.folder-div, .file-div {
    width: 100px;
    text-align: center;
    margin: 0;
    cursor: pointer;
}

.fa-stack {
    display: inline-block;
    height: 37px;
    line-height: 40px;
    position: relative;
    width: 2em;
}

.single-file-or-folder {
    position: relative;
    margin-top: -15px;
    border: solid 1px #acd718;
    min-height: 130px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    flex: 1 0 auto
}

.file-or-folder-actions {
    margin-top: -15px;
    border: solid 1px #acd718;
    width: 130px;
    height: 130px;
    background-color: #acd718;
    border-radius: 6px;
}

.hr-no-magrin hr {
    margin: 0 !important;
}

.add-file-or-folder {
    margin-top: 0;
}

.add-options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.folder-to-add .single-file-or-folder {
    border: dotted 2px #bab8aa;
    margin-top: 5px;
}

.folder-to-add .file-or-folder-options {
    background-color: #bab8aa;
}

.folder-to-add {
    color: #bab8aa;
    margin-top: 20px !important;
}

.single-file-or-folder .fas {
    padding-top: 10px
}

.file-or-folder-options {
    position: relative;
    right: 0;
    top: 0;
    background-color: #acd718;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    z-index: 4;
}

.file-or-folder-options span {
    font-size: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.flex-options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: -15px;
}

.file-or-folder-options-close {
    position: relative;
    right: 0;
    top: 0;
    background-color: #acd718;
    border: 1px solid white;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    z-index: 4;
}

.file-or-folder-options-close span {
    font-size: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.green-color {
    color: #acd718;
}

.orange-color {
    color: #ff9600;
}

.grey-color {
    color: #575757;
}

.light-grey-color {
    color: #bab8aa;
}

.white-color {
    color: #ffffff
}

.embed-pop-up {
    position: absolute;
    top: 38px;
    left: 0;
    width: 100%;
    padding: 0;
    z-index: 6;
    height: 100%;
}

.embed-pop-up i {
    position: absolute;
    right: 10px;
    font-size: 25px;
    padding: 10px;
    top: -36px;
    cursor: pointer;
}

.greyed-out {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(63, 62, 62);
    width: 100%;
    height: 100%;
    z-index: 6;
}

.data-box {
    margin: 5px 20px 5px 10px;
    display: flex;
}

.page-content .page-div.storage-container {
    max-width: 410px;
    margin: 0;
    padding: 0;
    min-height: calc(100vh - 140px);
    left: 50%;
    transform: translateX(-50%);
}

.folder-name {
    text-transform: uppercase;
    word-wrap: break-word;
    width: 120px;
}

#choose-options {
    display: none;
}

.options-list i {
    font-size: 16px !important;
}

.options-list span {
    font-size: 12px !important;
    text-transform: uppercase;
}

.options-list {
    margin-left: 0;
    margin-right: 0;
    height: 42px;
}

.option-no-padding {
    padding-left: 0;
    padding-right: 0;
}

.option-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.option-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.small-icon {
    font-size: 12px !important;
    padding-top: 0 !important;
}

#deleteConfirmPopup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    background: white;
    border: 1px solid grey;
    width: 300px;
    box-shadow: 0 0 8px 3px grey;
    text-align: center;
    border-radius: 6px;
}

#deleteConfirmPopup .popup-title {
    height: 38px;
    background-color: #ffd600;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 5px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#deleteConfirmPopup .popup {
    padding: 30px 15px;
    height: 100px;
}

#successPopup {
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    background: white;
    border: 1px solid grey;
    width: 300px;
    box-shadow: 0 0 8px 3px grey;
    text-align: center;
    border-radius: 6px;
}

#successPopup .popup-title {
    height: 38px;
    background-color: #ffd600;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 5px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#successPopup .popup {
    padding: 30px 15px;
    height: 100px;
}

.file-name {
    margin-top: 10px;
    word-wrap: break-word;
    width: 120px;
}

.path-style {
    color: white;
    font-weight: bold;
    padding: 10px;
    background-color: grey;
}

.storage-go-back {
    padding: 10px;
    display: block;
    background-color: #e7e7e7;
    min-height: 44px;
    z-index: 100;
}

#registerConfirmPopup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    background: white;
    border: 1px solid grey;
    width: 300px;
    box-shadow: 0 0 8px 3px grey;
    text-align: center;
    border-radius: 6px;
}

#registerConfirmPopup .popup-title {
    height: 38px;
    background-color: #ffd600;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 5px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#registerConfirmPopup .popup {
    padding: 15px;
}

.error-line {
    color: red;
    margin-bottom: 0;
    font-size: 14px;
}

.buttons-row {
    display: flex;
    justify-content: space-between;
}

.buttons-row button {
    width: calc(50% - 5px);
}

.first-letter-big:first-letter {
    text-transform: capitalize;
}

.popup-flex-close {
    position: absolute;
    background-color: gold;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -15px;
    right: -15px;
    border: white 1px solid;
}

.popup input {
    width: 100%;
}

.add-row {
    margin-bottom: 10px;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 101;
}

.overlay-storage {
    position: fixed;
    width: 100%;
    height: 200vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.overlay-falldetector {
    position: fixed;
    width: 105vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 101;
}

.overlay-success {
    position: fixed;
    width: 105vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    overflow: hidden;
}

.page-position-absolute {
    position: absolute;
}

@media (max-width: 600px) {
    .overlay {
        left: -15px;
    }

    .overlay-falldetector {
        left: -15px;
    }
}

.header-icons button {
    background-color: gold;
    border: none;
    padding-left: 0;
}

.success-line {
    color: #acd718;
    margin-bottom: 0;
    font-size: 14px;
}

.error-line-phone {
    color: red;
    margin-bottom: 5px;
    font-size: 14px;
    margin-top: -10px;
}

input[type=number] {
    -moz-appearance: textfield;
}

.width-auto {
    width: unset;
    display: inline-block;
    font-size: 10px;
    margin: 10px 5px 18px 5px;
    border-radius: 2px;
}

.width-auto i {
    margin: 0 7px;
    font-size: 12px;
}

.upper {
    text-transform: uppercase;
    font-size: 14px;
}

.required-text {
    color: red;
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.single-agreement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 12px;
}

.single-agreement input {
    height: 15px;
    padding: 0;
    box-shadow: 0 0 0 0 gray;
}

.single-agreement p {
    margin-top: 8px;
    text-align: left;
}

.width-5 {
    width: 5% !important;
}

.width-90 {
    width: 90% !important;
}

.width-95 {
    width: 95% !important;
}

.dirs-flex {
    display: flex;
    margin-left: 20px;
}

.dirs-flex p {
    margin-left: 20px !important;
}

.dir-icon {
    display: flex;
    justify-content: flex-start;
}

.dir-icon p {
    margin-left: 20px !important;
}

.getid-button {
    background-color: white;
    border: none;
    display: flex;
    cursor: pointer;
    width: 100%;

}

.getid-button-subdir {
    background-color: white;
    border: none;
    display: flex;
    cursor: pointer;
    width: 200px;
    margin-left: 20px;

}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: black;
    display: inline-block;
    cursor: pointer;
}

.getid-button-chosen {
    border: solid 1px #acd718;
    background-color: #acd718;
}

.chosen-file-name {
    display: flex;
}

.change-label {
    position: absolute;
    right: 15px;
    top: 50px;
    font-size: 16px !important;
}

.underline-file-name {
    text-decoration: underline;
    word-wrap: break-word;
}

.regulations {
    text-decoration: underline;
    font-weight: bold;
    padding-left: 5px;
}

.logo-plus-buttons {
    display: flex;
    flex-direction: row;
}

.language-buttons {
    position: absolute;
    right: 10px;
    top: 30px;
    display: flex;
    flex-direction: column;
}

.language-buttons img {
    height: 25px;
    width: auto;
}


.language-buttons-settings {
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: space-around;

}

.language-buttons-settings img {
    height: 50px;
    width: auto;
    margin-bottom: 0;
}


.language-buttons-settings {
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}

@media (min-width: 470px) {
    .language-buttons-settings {
        padding: 25px;
        width: 426px;
        margin-left: auto;
        margin-right: auto
    }
}

.extra-margin-top-health {
    margin-top: 60px;
    padding-top: 10px !important;
}

.extra-margin-top {
    margin-top: 45px;
}

.account {
    margin-top: 10px;
}

.settings {
    margin-top: 0;
}

.onoffswitch {
    position: relative;
    width: 90px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #999999;
    border-radius: 20px;
}

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #FFD600;
    color: #808080;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #E7E7E7;
    color: #999999;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.onoffswitch-switch {
    display: block;
    width: 25px;
    margin: 5px;
    height: 25px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 56px;
    border: 2px solid #999999;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0;
}

.header-with-switch {
    display: flex;
    justify-content: space-between;
}

.truncate {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.switch-in-header {
    display: flex;
    justify-content: space-between;
}

.toggle {
    margin: 10px 0 0;
    box-sizing: border-box;
    font-size: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

}

.toggle input {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
}

.toggle label {
    width: 41vw;
}

.toggle input + label {
    padding: 5px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    border: solid 1px black;
    background-color: #FFF;
    font-size: 1rem;
    line-height: 140%;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    transition: border-color .15s ease-out,
    color .25s ease-out,
    background-color .15s ease-out,
    box-shadow .15s ease-out;
    cursor: pointer;
    color: black;
    justify-content: center;
}

.toggle input:hover + label {
    border-color: grey;
}

.toggle input:checked + label {
    background-color: rgb(255, 214, 0);;
    color: black;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    border-color: black;
    z-index: 1;
}

/*.toggle input:focus + label {*/
/*outline: dotted 1px #CCC;*/
/*outline-offset: .45rem;*/
/*}*/

.sidebar-box {
    background-color: white;
    height: 100vh;
    width: 275px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 102;
}

.menu-icon {
    position: absolute;
    left: 20px;
}

.sidebar-header {
    background-image: url("../images/sidebar-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left bottom;
    width: 100%;
    min-height: 80px;
    display: flex;
    flex-direction: row;
}

.round-flag {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 20px;
}

@media (max-width: 470px) {
    .round-flag {
        margin-left: 10px;
    }
}

.round-flag img {
    width: auto;
    height: 100%;
    margin-left: -6px;
}

.sidebar-header p {
    font-size: 11px;
    color: white;
    margin-bottom: 0;
    line-height: 1.2;
}

.user-info {
    margin-left: 15px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sidebar-box ul {
    list-style: none;
}

.sidebar-box li:before {
    width: 0;
}

.sidebar-box li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 35px;
}

.sidebar-box li a {
    align-items: center
}

.sidebar-box li p {
    margin-left: 10px;
    margin-bottom: 0;
    align-items: center;
}

/*.sidebar-box li i {*/
/*border: solid 1px #555;*/
/*padding: 10px;*/
/*background-color: gold;*/
/*border-radius: 6px;*/
/*}*/

.icon-box {
    width: 30px;
    height: 30px;
    background-color: gold;
    border: solid 1px #555;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-box img {
    width: 15px;
    height: 15px;
    color: black
}

.sidebar-in {
    animation-duration: 500ms;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        margin-left: -100%;
    }

    to {
        margin-left: 0;
    }
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-form button {
    width: 80%;
}

.full-width-input {
    width: 100%;
}

.part-width-button {
    width: 60%;
}

.full-width-button {
    width: 100%;
}

.edit-button {
    margin-top: 10px;
}

.padding-right-15 {
    padding-right: 15px;
}

.sidebar-button {
    background-color: white;
    border: none;
    width: 200px;
    display: flex;
    padding-left: 0;
    align-items: center;
    color: black;
    cursor: pointer;
}

.inputs-lang {
    flex-direction: column;
}

.radio-with-flag {
    display: flex;
    align-items: center;
}

.flag {
    width: 40px;
    margin-left: 10px;
    border: solid 1px gray;
}

.flag-no-margin {
    width: 40px;
    height: 100%;
    border: solid 1px gray;
}

.chosen-lang {
    align-items: center;
    height: 40px;
    display: flex;
}

.chosen-lang p {
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.radio-with-flag label {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.radio-with-flag label p {
    padding-left: 10px;
}

.radio-with-flag label input {
    margin-bottom: 0;
}

.radio-with-flag label input:focus {
    outline: none;
}

.need-phone-number {
    padding-right: 50px;
}

.swipe-area {
    width: 25%;
    height: 100vh;
    position: absolute;
    z-index: 9;
}

.swipe-area-narrow {
    width: 12%;
    height: 100vh;
    position: absolute;
    z-index: 9;
}

.save-button {
    height: 38px;
    padding: 0;
}

.margin-minus {
    margin-left: -20px;
}


.value-setting {
    position: absolute;
    z-index: 4;
}

.arc-transparent {
    opacity: 0.5;
    position: absolute;
    left: 0;
}

#successPopup {
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    background: white;
    border: 1px solid grey;
    width: 300px;
    box-shadow: 0 0 8px 3px grey;
    text-align: center;
    border-radius: 6px;
}

#successPopup .popup-title {
    height: 38px;
    background-color: #ffd600;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 5px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#successPopup .popup {
    padding: 30px 15px;
    height: 100px;
}

#passwordPopup {
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    background: white;
    border: 1px solid grey;
    width: 300px;
    box-shadow: 0 0 8px 3px grey;
    text-align: center;
    border-radius: 6px;
}

#passwordPopup .popup-title {
    height: 38px;
    background-color: #ffd600;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 5px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#passwordPopup .popup {
    padding: 15px;
    height: 130px;
}

.main-speedometer {
    z-index: 3;
}

.dots-speedometer {
    position: absolute;
    z-index: 1;
}


/*.value-setting {*/
/*position: absolute;*/
/*}*/

/*.arc-transparent {*/
/*opacity: 0.5;*/
/*position: absolute;*/
/*left: 0;*/
/*}*/

.ontop {
    z-index: 50 !important;
}

.sound-div {
    margin-bottom: 60px !important;
}

.tutorial-box {
    height: auto;
    background-color: white;
    max-width: 426px;
    margin-left: auto;
    margin-right: auto
}

.tutorial-icon {
    height: 50vh;
}

.tutorial-info {
    min-height: 35vh;
    padding: 20px;
    height: auto;
}

.tutorial-button {
    height: auto;
    padding: 10px 20px;
    width: 100%;
}

.tutorial-icon {
    background-color: #bfbfbf;
    background-image: linear-gradient(#bfbfbf, grey);
}

.tutorial-icon i {
    font-size: 120px;
    color: gold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.language-buttons-tutorial {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    flex-direction: column;
}

.language-buttons-tutorial img {
    height: 25px;
    width: auto;
    margin-bottom: 5px;
}

.dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 40px;
    align-items: center;
}

.nav-dot {
    border-radius: 50%;
    height: 20px;
    border: none;
    width: 20px;
    cursor: pointer;
    margin: 5px;
    background-color: grey;
}

.dots input[type="radio"] {
    display: none;
}

.dots input[type="radio"] + label {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: grey;
    margin: 5px;

}

.dots input[type="radio"]:checked + label {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: gold;
    border: 4px solid grey;
    margin: 5px;
}

.password-change {
    margin-top: -45px;
}

.to-left {
    text-align: left;
}
